import { Injectable, inject } from '@angular/core';
import { FaqApiResponse } from '@core/models-api/cms-api.model';
import { Observable } from 'rxjs';
import { RequestService } from '../../services/request/request.service';
import { ConfigurationsApiResponse, CountryConfigurationsApiResponse } from '@core/models-api/app-api.model';

@Injectable({ providedIn: 'root' })
export class ApiCmsService {

  /** Dependencies */
  private requestService = inject(RequestService);

  /**
    * Fetch app global configurations
    */
  public fetchConfigurations(): Observable<ConfigurationsApiResponse> {
    const url = 'configurations';
    return this.requestService.get(url, {}, false);
  }

  /**
    * Fetch country configurations
    */
  public fetchCountryConfigurations(): Observable<CountryConfigurationsApiResponse> {
    const url = 'countryConfigurations';
    return this.requestService.get(url, {}, false);
  }

  /**
   * Fetch faqs
   */
  public fetchFaqs(): Observable<FaqApiResponse> {
    const url = 'faqs';
    return this.requestService.get(url, {}, false);
  }

}
